import React from "react"
import { Link } from "gatsby"
// Style Imports
import './styles.scss'
// SEO
import Seo from '@components/utility/SEO'
// Structural Imports
import Layout from "@components/structure/Layout.jsx"
// Content Imports
import Hero from "@components/content/hero/Hero.jsx"
import Image from "@components/content/fractals-image.jsx"

const page404 = () => (
    <Layout addedClass="page--error page--404">
        <Seo title="Page Not Found | Lupkynis.com" description="" robots="noindex" canonicalURL="https://www.lupkynis.com/404" />
        <Hero addedClass="bg-light-green hero--string">
            <div className="col-wrapper">
                <div className="container">
                    <div className="hero__content">
                        <h1 className="heading heading--accent">HMMM...</h1>
                        <h2>Sorry, this page does not exist</h2>
                        <p>You may have an incorrect URL or you might have arrived at this page by mistake. Let's see if you can find what you're looking for back on the homepage.</p>
                        <Link to="/" className="button">Return to home</Link>
                    </div>
                    <div className="hero__image">
                        <Image data={{desktopSrc: 'hero-warning.png', mobileSrc: 'hero-warning--mobile.png'}} />
                    </div>
                </div>
            </div>
        </Hero>
    </Layout>
)

export default page404