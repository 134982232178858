import PropTypes from "prop-types"
import React from "react"

// Style Imports
// import "./Hero.scss"
// Content Imports

function Hero({ addedClass, children }) {

    return (
        <section className={"hero " + (addedClass ? addedClass : "")}>
            <div className="container">
                {children}
            </div>
        </section>
    )
}

export default Hero

Hero.propTypes = {
    addedClass: PropTypes.string,
}

Hero.defaultProps = {
    addedClass: '',
}
